import { BaseModelFactory, BaseModelFactoryInterface } from '@api/models/';
import { UserSettingsModel } from './';

export class UserSettingsFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = UserSettingsModel;

  constructor() {
    super();
  }

  override build(data: object): UserSettingsModel {
    return super.build(data);
  }

  override buildCollection(data: Array<object>): Array<UserSettingsModel> {
    return super.buildCollection(data);
  }

}

// export const userSettingsFactory = new UserSettingsFactory();

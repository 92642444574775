<div class="mip-loading">
  <mip-icon
    icon="refresh"
    [spin]="true"
    [size]="size"
    [bold]="true"
    [mipTextColor]="color"
  ></mip-icon>
  <span class="__content"><ng-content></ng-content></span>
</div>

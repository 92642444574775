<div class="__error-content">

  <mip-icon
    class="__badge-icon"
    icon="crying-face"
    size="12x"
  ></mip-icon>

  <p
    class="__content mip-color--text-white --text-align--center"
    mipTranslate="error.general-error.message"
  >
    Something went wrong while trying to access this page.
  </p>

</div>

<div class="__actions-content">
  <mip-error-actions></mip-error-actions>
</div>

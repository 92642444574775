import { BaseModel, PostChallengeInterface, PostModel, UserModel } from '@api/models';
import { ModelFactoryService } from '@services/model-fectory/model-factory.service';

export class PostChallengeModel extends BaseModel implements PostChallengeInterface {

  id = null; // api
  coOwnerAccepted = null; // api
  stats = null; // api

  // model dependencies
  originalPost: PostModel = null; // api
  coOwner: UserModel = null; // api

  constructor(model?: PostChallengeInterface) {
    super(model);

    this.fill(model);

  }

  override afterFill(model) {
    this.originalPost = ModelFactoryService.instance.postFactory.build(model.originalPost);
    this.coOwner = ModelFactoryService.instance.userFactory.build(model.coOwner);
  }

}

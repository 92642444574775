import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MipAvatarModule } from '@components/mip-avatar/mip-avatar.module';
import { MipIconModule } from '@components/mip-icon/mip-icon.module';
import { MipProfileSlugNameBadgesComponent } from '@components/mip-profile-slug-name/mip-profile-slug-name-badges/mip-profile-slug-name-badges.component';
import { ColorModule } from '@directives/color/color.module';
import { MipProfileSlugNameComponent } from './mip-profile-slug-name.component';

@NgModule({
  declarations: [MipProfileSlugNameComponent, MipProfileSlugNameBadgesComponent],
  exports: [
    MipProfileSlugNameComponent
  ],
  imports: [
    CommonModule,
    MipIconModule,
    ColorModule,
    ColorModule,
    MatTooltipModule,
    MipAvatarModule,
    RouterModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class MipProfileSlugNameModule {
}

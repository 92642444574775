import {
  BaseModelFactory,
  BaseModelFactoryInterface
} from '@api/models/';
import {
  PostInterface,
  PostModel
} from './';

export class PostFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = PostModel;

  allowedThemes = ['single', 'challenge', 'duet'];

  constructor() {
    super();
    // console['logger'].log('post.factory->constructor(): PostModel', PostModel);
  }

  override build(data: PostInterface): PostModel {
    // resetting all posts as single for now.
    // NOT SUPPORTING duet AND  challenge posts
    data.theme = 'single';
    return super.build(data);
  }

  override buildCollection(data: Array<PostInterface>): Array<PostModel> {

    const collection = [];

    if (data) {
      for (const item of data) {
        if (item.medias && (!item.theme || this.allowedThemes.indexOf(item.theme) > -1)) {
          // resetting all posts as single for now.
          // NOT SUPPORTING duet AND  challenge posts
          item.theme = 'single';
          collection.push(item);
        }
      }
    }

    return super.buildCollection(collection);

  }

}

// export const postFactory = new PostFactory();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MipAvatarModule } from '@components/mip-avatar/mip-avatar.module';
import { MipIconModule } from '@components/mip-icon/mip-icon.module';
import { MipProfileSlugNameModule } from '@components/mip-profile-slug-name/mip-profile-slug-name.module';
import { ColorModule } from '@directives/color/color.module';
import { MipTranslateModule } from '@directives/translate/mip-translate.module';
import { ErrorActionsModule } from '@modules/error/actions/error-actions.module';
import { ForbiddenGuard } from './forbidden-guard.service';

import { ForbiddenRoutingModule } from './forbidden-routing.module';
import { ForbiddenComponent } from './forbidden.component';


@NgModule({
  declarations: [ForbiddenComponent],
  imports: [
    CommonModule,
    ForbiddenRoutingModule,
    MipIconModule,
    MatButtonModule,
    MipTranslateModule,
    ColorModule,
    MipAvatarModule,
    MipProfileSlugNameModule,
    MatIconModule,
    ErrorActionsModule
  ],
  providers: [
    ForbiddenGuard
  ]
})
export class ForbiddenModule {
}

import { environment } from '@environments/environment';
import { utilsFactory } from './utils.factory';

class DebugFactory {

  disableAll = false;

  styles = {
    error: ['background: red', 'padding: 5px', 'color: white', 'display: block'].join(';'),
    warn: ['background: yellow', 'padding: 5px', 'color: black', 'display: block'].join(';'),
    success: ['background: green', 'padding: 5px', 'color: white', 'display: block'].join(';'),
    info: ['background: blue', 'padding: 5px', 'color: white', 'display: block'].join(';')
  };

  logsEnabled = true;
  screenEnabled = true;
  warnsEnabled = true;
  successEnabled = true;
  errorEnabled = true;
  infosEnabled = true;

  activeLogs = {};

  constructor() {
    console.log('DebugFactory->constructor()');

    if (environment.originalLogs) {
      console['allowNamespace'] = () => {
      };
      console['logger'] = console;
    }
    else {
      console['logger'] = this;
      console.log('DebugFactory->constructor() [logger]');
    }

    // console['logger'].log('DebugFactory->constructor(): environment.disableLogs', environment.disableLogs);

    if (utilsFactory.isBrowser) {
      if (environment.disableLogs || window.localStorage.getItem('mip-force-enable-logs') === 'true') {
        this.disableAll = true;
      }
    }

    // console['logger'].log('DebugFactory->constructor(): this.disableAll', this.disableAll);

    if (this.disableAll) {
      this.logsEnabled = false;
      this.screenEnabled = false;
      this.warnsEnabled = false;
      // this.successEnabled = false;
      // this.errorEnabled = false;
      this.infosEnabled = false;
    }
  }

  getArgs(args, removePositions?) {

    try {
      const finalArgs = ['%cMIP', 'color: #FFF; font-weight: bold; background: #DC2975; padding: 5px'];

      for (const i in args) {
        if (args[i] !== 'styled' && args[i] !== 'forced' && (!removePositions || (removePositions && removePositions.indexOf(args[i]) === -1))) {
          finalArgs.push(args[i]);
        }
      }

      return finalArgs;
    }
    catch (e) {
      console.error(e);
    }
  }

  allowNamespace(namespace) {
    this.activeLogs[namespace] = true;
  }

  force(args) {

    let forced = false;
    // console.log('DebugFactory->force(): this.activeLogs', this.activeLogs);

    if (args[0] === 'forced') {
      forced = args[0] === 'forced';
    }
    else if (args[0]) {
      const namespace = args[0].split('->')[0];
      // console.log('DebugFactory->force(): namespace', namespace);
      forced = this.activeLogs[namespace];
      // console.log('DebugFactory->force(): namespace', namespace, forced);
    }

    return forced;

  }

  log(...args) {
    try {
      if (this.force(args) || this.logsEnabled) {
        console.log.apply(null, this.getArgs(args));
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  preLog(type, args) {
    try {
      const styled = args[0] === 'styled' || args[1] === 'styled';
      const forced = args[0] === 'forced' || args[1] === 'forced';

      if (forced || this.errorEnabled) {
        if (styled) {
          // tslint:disable-next-line:no-console
          console['logger'].info(`%c ${this.getArgs(args).join(', ')}`, this.styles[type]);
        }
        else {
          console[type === 'success' ? 'info' : type].apply(null, this.getArgs(args));
        }
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  error(...args) {
    try {
      if (this.errorEnabled) {
        this.preLog('error', args);
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  warn(...args) {
    try {
      if (this.force(args) || this.warnsEnabled) {
        this.preLog('warn', args);
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  success(...args) {
    try {
      if (this.force(args) || this.successEnabled) {
        this.preLog('success', args);
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  info(...args) {
    try {
      if (this.infosEnabled) {
        this.preLog('info', args);
      }
    }
    catch (e) {
      console.error(e);
    }
  }
}

export default new DebugFactory();

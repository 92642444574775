import {
  Component,
  Inject,
  Optional
} from '@angular/core';
import { UserModel } from '@api/models';
import { UserService } from '@api/services/user.service';
import { ObserverHelper } from '@helpers/observer.helper';
import { RESPONSE } from '@nguniversal/express-engine/tokens';

@Component({
  selector: 'mip-error-actions',
  templateUrl: './error-actions.component.html',
  styleUrls: ['./error-actions.component.scss']
})
export class ErrorActionsComponent {

  loggedInUser: UserModel = null;

  observerHelper = new ObserverHelper();

  constructor(
    private userService: UserService,
    @Optional() @Inject(RESPONSE) private response: any
  ) {
    this.observerHelper.addSubscription(
      this.userService.onUserAuthentication().subscribe(user => {
        // console['logger'].log('event.component->ngOnInit(): onUserAuthentication()', user);
        if (user) {
          this.loggedInUser = user;
        }
      })
    );
  }
}

import { BaseModelFactory, BaseModelFactoryInterface } from '@api/models/';
import { CommentInterface, CommentModel } from './';

export class CommentFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = CommentModel;

  constructor() {
    super();
  }

  override build(data: CommentModel): CommentModel {
    return super.build(data);
  }

  override buildCollection(data: Array<CommentInterface>): Array<CommentModel> {
    return super.buildCollection(data);
  }

}

// export const commentFactory = new CommentFactory();

import {
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import {
  NgModule,
  PLATFORM_ID
} from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { utilsFactory } from '@factories/utils.factory';
import {
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { I18nService } from './i18n.service';
import { translateLoaderFactory } from './translate-loader';

@NgModule({
  imports: [
    HttpClientModule,
    // BrowserTransferStateModule,
    TranslateModule.forRoot({
      defaultLanguage: (() => {

        let lang;

        if (utilsFactory.isBrowser) {
          console['logger'].log('i18n.module->defaultLanguage', utilsFactory.getCookieByName('lang'));
          lang = utilsFactory.getCookieByName('lang');
        }

        return lang || 'en'; // lang can be undefined

      })(),
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient, TransferState, PLATFORM_ID]
      }
    })
  ],
  exports: [TranslateModule]
})
export class I18nModule {

  constructor(
    private i18nService: I18nService
  ) {

    setTimeout(() => {
      this.i18nService.init();
    }, 50);

  }

}

import {
  DecimalPipe,
  DOCUMENT
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  Inject,
  NgModule
} from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MipLoadingModule } from '@components/mip-loading/mip-loading.module';
import { environment } from '@environments/environment';
import { utilsFactory } from '@factories/utils.factory';
import { I18nModule } from '@services/i18n/i18n.module';
import { MobileDetectService } from '@services/mobile-detect/mobile-detect.service';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination
} from 'swiper';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

SwiperCore.use([Navigation, Pagination, Autoplay]);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    I18nModule,
    MipLoadingModule
  ],
  providers: [
    DecimalPipe,
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private mobileDetect: MobileDetectService,
    @Inject(DOCUMENT) private document: Document
  ) {

    console['logger'].log('app.module->constructor(): environment', environment);

    if (this.mobileDetect.isMobile()) {
      utilsFactory.addClass(document.body, '--is-mobile');
    }
    else {
      utilsFactory.addClass(document.body, '--is-desktop');
    }

    if (utilsFactory.isBrowser) {
      console['logger'].log('app.module->constructor(): window.localStorage.getItem()', window.localStorage.getItem('mip-force-enable-log'));

      if (window.localStorage.getItem('mip-force-enable-log') === 'true') {
        environment.disableLogs = false;
      }
    }

    // console['logger'].log('app.module->constructor(): utilsFactory.isBrowser && environment.disableLogs', utilsFactory.isBrowser && environment.disableLogs);
    // console['logger'].log('app.module->constructor(): utilsFactory.isSSR && environment.disableLogsServer', utilsFactory.isSSR && environment.disableLogsServer);

  }
}

import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  makeStateKey,
  TransferState
} from '@angular/platform-browser';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { readFileSync } from 'fs';
import { join } from 'path';
import {
  Observable,
  of
} from 'rxjs';

export class TranslateBrowserLoader implements TranslateLoader {

  constructor(
    private transferState: TransferState,
    private http: HttpClient,
    private prefix: string = './assets/i18n/',
    private suffix: string = '.json'
  ) {
  }

  public getTranslation(lang: string): Observable<any> {
    // console['logger'].log('translate-loaders->TranslateBrowserLoader(): getTranslation() lang', lang);

    const key = makeStateKey<any>('transfer-translate-' + lang);
    // console['logger'].log('translate-loaders->TranslateBrowserLoader(): getTranslation() key', key);

    const data = this.transferState.get(key, null);
    // console['logger'].log('translate-loaders->TranslateBrowserLoader(): getTranslation() data', data);

    const suffix = `${this.suffix}`; // `.${i18nMap[lang]}${this.suffix}`;

    // First we are looking for the translations in transfer-state, if none found, http load as fallback
    return data
      ? of(data)
      : new TranslateHttpLoader(this.http, this.prefix, suffix).getTranslation(lang);
  }
}

export class TranslateFSLoader implements TranslateLoader {
  constructor(
    private transferState: TransferState,
    private prefix = './assets/i18n/',
    private suffix = '.json'
  ) {
  }

  /**
   * Gets the translations from the server, store them in the transfer state
   */
  public getTranslation(lang: string): Observable<any> {
    // console['logger'].log('translate-loaders->TranslateFSLoader(): getTranslation() lang', lang);

    const path = join(__dirname, '../browser', this.prefix, `${lang}${this.suffix}`); // .${i18nMap[lang]}
    const data = JSON.parse(readFileSync(path, 'utf8'));
    // console['logger'].log('translate-loaders->TranslateFSLoader(): getTranslation() data', data);

    const key = makeStateKey<any>('transfer-translate-' + lang);
    // console['logger'].log('translate-loaders->TranslateFSLoader(): getTranslation() key', key);

    this.transferState.set(key, data);

    return of(data);

  }
}

export function translateLoaderFactory(httpClient: HttpClient, transferState: TransferState, platform: any) {
  // console['logger'].log('translate-loaders->translateLoaderFactory()');
  const prefix = './assets/i18n/';
  return isPlatformBrowser(platform)
    ? new TranslateBrowserLoader(transferState, httpClient, prefix)
    : new TranslateFSLoader(transferState, prefix);
}

import {
  BaseModelFactory,
  BaseModelFactoryInterface
} from '@api/models/';
import {
  TopTenRankingInterface,
  TopTenRankingModel
} from './';

export class TopTenRankingFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override model = TopTenRankingModel;

  constructor() {
    super();
    // console['logger'].log(ContestModel, ContestModel);
  }

  override build(data: TopTenRankingInterface): TopTenRankingModel {
    return super.build(data);
  }

  override buildCollection(data: Array<TopTenRankingInterface>): Array<TopTenRankingModel> {
    return super.buildCollection(data);
  }

}

// export const topTenRankingFactory = new TopTenRankingFactory();

import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserModel } from '@api/models';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mip-profile-slug-name,[mip-profile-slug-name]',
  templateUrl: './mip-profile-slug-name.component.html',
  styleUrls: ['./mip-profile-slug-name.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MipProfileSlugNameComponent implements OnInit, OnChanges {

  @Input() user: UserModel = null;

  // for manual setup
  @Input() link: string = null;
  @Input() route: string = null;
  @Input() name: string = null;
  @Input() avatarImg: string = null;

  @Input() horizontalCenter = false;

  @Input() theme = 'dark';
  @Input() showBadges = true;
  @Input() badgeSize = '3x';
  @Input() profileSlugName: string;
  @Input() enableProfileLink = false;
  @Input() openProfileLinkNewPage = false;
  @Input() showName = !!this.name;
  @Input() showEmail = false;

  @Input() inlineUsername = false;

  @Input() showCopyIcon = false;

  @Input() showAvatar = !!this.avatarImg;
  @Input() avatarSize: string | number | Array<number> = 50;
  @Input() avatarIcon: string = null;
  @Input() avatarIconColor: string = null;
  @Input() avatarBorderRadius: string = '50%';
  @Input() avatarElevation: number = null;

  assetsPath = environment.assetsPath;

  constructor(
    private matSnackBar: MatSnackBar,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {

  }

  ngOnInit() {
    // console['logger'].log('mip-profile-slug-name.component->ngOnInit(): this.user', this.user);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console['logger'].log('mip-profile-slug-name.component->ngOnChanges(): changes', changes);

    if (changes['user']) {

      if (this.user) {
        this.profileSlugName = this.user.username;

        if (!this.profileSlugName) {
          throw new Error('[profileSlugName] must be provided.');
        }
      }

    }
  }

  copyValue(value) {

    const copyValue = this.document.createElement('input');

    copyValue.setAttribute('value', value);
    this.document.body.appendChild(copyValue);

    copyValue.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(copyValue);

    this.translate.get('share-modal.coped-successfully').subscribe((response) => {
      this.matSnackBar.open(response, null, {
        duration: 1500,
        panelClass: 'mip-color--text-success'
      });
    });

  }
}

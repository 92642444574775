import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import { DowntimeComponent } from './downtime.component';


const routes: Routes = [{
  path: '',
  component: DowntimeComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DowntimeRoutingModule {
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ColorModule } from '@directives/color/color.module';
import { MipIconModule } from '../mip-icon/mip-icon.module';
import { MipLoadingComponent } from './mip-loading.component';


@NgModule({
  declarations: [MipLoadingComponent],
  imports: [
    CommonModule,
    MipIconModule,
    ColorModule
  ],
  exports: [MipLoadingComponent]
})
export class MipLoadingModule {
}

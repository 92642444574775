import {
  BaseModelFactory,
  BaseModelFactoryInterface
} from '@api/models/';
import { CollabAmbassadorModel } from './';

export class CollabAmbassadorFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = CollabAmbassadorModel;

  constructor() {
    super();
    // console['logger'].log('this.model', this.model);
  }

  override build(data: object): CollabAmbassadorModel {
    // console['logger'].log('dashboard-user.factory->build(): data', data);
    // console['logger'].log('dashboard-user.factory->build(): data.settings', data.settings);
    // console.trace('dashboard-user.factory->build(): data', data);
    return super.build(data);
  }

  override buildCollection(data: Array<object>): Array<CollabAmbassadorModel> {
    return super.buildCollection(data);
  }

}

// export const userFactory = new UserFactory();

<div
  class="avatar"
  [style.margin]="(borderWidth || roundProgressMargin) + 'px'"
  [style.borderRadius]="borderRadius"
>
  <div class="__crown" *ngIf="crown">
    <img src="{{assetsPath}}/images/crowns/{{crown}}.png">
  </div>

  <div
    #avatar
    class="__avatar"
    [class]="getClasses()"
    *ngIf="source || userModelSourceUrl"
    [style.backgroundColor]="bgColor"
    [style.width]="iconStyles.width"
    [style.height]="iconStyles.height"
    [style.minWidth]="iconStyles.width"
    [style.minHeight]="iconStyles.height"
    [style.maxWidth]="iconStyles.width"
    [style.maxHeight]="iconStyles.height"
    [style.borderRadius]="borderRadius"
    [class.--spin]="spin"
  >
    <img
      class="__avatar-img"
      [style.backgroundImage]="'url(' + (sourceUrl || userModelSourceUrl) + ')'"
      [style.borderRadius]="borderRadius"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpERDYyMzAzQTg2RTExMUU0OEZDMEJFQUQ5RTIwNTk0QyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpERDYyMzAzQjg2RTExMUU0OEZDMEJFQUQ5RTIwNTk0QyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkRENjIzMDM4ODZFMTExRTQ4RkMwQkVBRDlFMjA1OTRDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkRENjIzMDM5ODZFMTExRTQ4RkMwQkVBRDlFMjA1OTRDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+7/j31wAAABpJREFUeNpi/P//PwMxgImBSDCqkDoKAQIMANUSAxFqvpFcAAAAAElFTkSuQmCC"
      width="100%"
    >
    <span
      class="__user-prefix"
      *ngIf="userPrefix"
      [style.fontSize]="userPrefixFontSize"
      [style.borderRadius]="borderRadius"
    >
      {{userPrefix}}
    </span>
    <span
      class="__border"
      *ngIf="border || borderColor"
      [ngClass]="bdClasses"
      [style.opacity]="borderOpacity/100"
      [style.borderRadius]="borderRadius"
    ></span>
    <span
      class="__content"
      [style.font-size]="fontSize"
    >
      <ng-content></ng-content>
    </span>
    <span
      class="__badge"
      [style.font-size]="fontSize"
    >
      <ng-content select="[avatar-badge]"></ng-content>
    </span>
  </div>

  <div
    #avatar
    class="__avatar"
    [class]="getClasses()"
    *ngIf="!sourceUrl && !userModelSourceUrl"
    [style.backgroundColor]="bgColor"
    [style.width]="iconStyles.width"
    [style.height]="iconStyles.height"
    [style.minWidth]="iconStyles.width"
    [style.minHeight]="iconStyles.height"
    [style.maxWidth]="iconStyles.width"
    [style.maxHeight]="iconStyles.height"
    [style.borderRadius]="borderRadius"
  >
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpERDYyMzAzQTg2RTExMUU0OEZDMEJFQUQ5RTIwNTk0QyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpERDYyMzAzQjg2RTExMUU0OEZDMEJFQUQ5RTIwNTk0QyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkRENjIzMDM4ODZFMTExRTQ4RkMwQkVBRDlFMjA1OTRDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkRENjIzMDM5ODZFMTExRTQ4RkMwQkVBRDlFMjA1OTRDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+7/j31wAAABpJREFUeNpi/P//PwMxgImBSDCqkDoKAQIMANUSAxFqvpFcAAAAAElFTkSuQmCC"
      width="100%"
    >
    <span
      class="__user-prefix"
      *ngIf="userPrefix"
      [style.fontSize]="userPrefixFontSize"
    >
      {{userPrefix}}
    </span>
    <span
      class="__border"
      *ngIf="border || borderColor"
      [class]="bdClasses"
      [style.opacity]="borderOpacity/100"
    ></span>
    <span
      class="__content"
      [style.font-size]="userPrefixFontSize"
    >
      <ng-content></ng-content>
    </span>
    <span
      class="__badge"
      [style.font-size]="fontSize"
    >
      <ng-content select="[avatar-badge]"></ng-content>
    </span>
  </div>

  <div
    class="__round-progress"
    [style.top]="'-' + (roundProgressMargin) + 'px'"
    [style.right]="'-' + (roundProgressMargin) + 'px'"
    [style.bottom]="'-' + (roundProgressMargin) + 'px'"
    [style.left]="'-' + (roundProgressMargin) + 'px'"
  >
    <round-progress
      *ngIf="roundProgress"
      [radius]="roundProgressRadius"
      [stroke]="roundProgressStroke"
      [current]="roundProgressCurrent"
      [max]="roundProgressMax"
      [duration]="roundProgressDuration"
      [background]="isDesktop ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.5)'"
      [color]="'#df0066'"
      animation="linearEase"
    ></round-progress>
    <!-- [color]="isDesktop ? '#df0066' : '#FFF'" -->
  </div>
</div>

<div
  #lottieContainer
  [class.--show]="!colored"
  class="__lottie"
></div>
<div
  #lottieColoredContainer
  [class.--show]="colored"
  class="__lottie --colored"
></div>

import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { environment } from '@environments/environment';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { RouterService } from '@services/router/router.service';
import { WebsocketService } from '@services/websocket/websocket.service';

@Component({
  selector: 'mip-downtime',
  templateUrl: './downtime.component.html',
  styleUrls: ['./downtime.component.scss']
})
export class DowntimeComponent implements OnInit, OnDestroy {

  assetsPath = environment.assetsPath;
  websocketOnCommandSubscription = null;

  constructor(
    private routerService: RouterService,
    private websocket: WebsocketService,
    @Optional() @Inject(RESPONSE) private response: any
  ) {
  }

  ngOnInit() {

    this.websocketOnCommandSubscription = this.websocket.onCommand('under-maintenance').subscribe(async (response) => {
      console['logger'].log('downtime.component->ngOnInit(): websocket', response);

      if (response.in_maintenance === false) {

        this.websocketOnCommandSubscription.unsubscribe();

        const goBackReferer = this.routerService.getGoBackReferer('/downtime');
        console['logger'].log('downtime.component->ngOnInit(): goBackReferer', goBackReferer);

        if (goBackReferer) {
          this.routerService.navigateTo(goBackReferer.path, {
            queryParams: goBackReferer.search
          });
        }
        else {
          this.routerService.navigateTo('/trending');
        }

      }
    });

    if (this.response) {
      this.response.status(503);
    }
  }

  ngOnDestroy() {
    if (this.websocketOnCommandSubscription) {
      this.websocketOnCommandSubscription.unsubscribe();
    }
  }

}

<div class="__error-content">

  <div class="__badge-icons-container">
    <mat-icon
      class="__badge-icon"
    >
      block
    </mat-icon>
    <!--<mat-icon
      class="__badge-icon"
    >
      lock
    </mat-icon>-->
  </div>

  <p
    class="__content mip-color--text-white --text-align--center"
    mipTranslate="error.forbidden.message"
  >
    You have no permission to access this page!
  </p>
</div>

<div class="__actions-content">
  <mip-error-actions></mip-error-actions>
</div>

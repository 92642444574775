import {
  BaseModel,
  UserSettingsInterface
} from '@api/models/';

export class UserSettingsModel extends BaseModel implements UserSettingsInterface {

  id = null;
  key = null;
  value = null;
  visibility = null;

  constructor(model?: UserSettingsInterface) {

    // console['logger'].log('user-settings.model->constructor(): model', [model.key, model.value], model)

    super(model);

    this.fill(model);

  }

}

import { EnvironmentInterface } from '../../environment.interface';

const settings: EnvironmentInterface = {
  defaultLanguage: 'hi',
  globalLanguages: ['en', 'hi'],
  userReferral: {
    code: 'YGFRYHR',
    username: 'GotChosenIndia'
  }
};

export const environment: EnvironmentInterface = settings;

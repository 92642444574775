import {
  CommonModule,
  NgOptimizedImage
} from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MipAvatarModule } from '@components/mip-avatar/mip-avatar.module';
import { MipIconModule } from '@components/mip-icon/mip-icon.module';
import { MipProfileSlugNameModule } from '@components/mip-profile-slug-name/mip-profile-slug-name.module';
import { ColorModule } from '@directives/color/color.module';
import { MipTranslateModule } from '@directives/translate/mip-translate.module';

import { DowntimeRoutingModule } from './downtime-routing.module';
import { DowntimeComponent } from './downtime.component';


@NgModule({
  declarations: [DowntimeComponent],
  imports: [
    CommonModule,
    DowntimeRoutingModule,
    MipIconModule,
    MatButtonModule,
    MipTranslateModule,
    ColorModule,
    MipAvatarModule,
    MipProfileSlugNameModule,
    NgOptimizedImage
  ]
})
export class DowntimeModule {
}

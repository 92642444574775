import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot
} from '@angular/router';
import { RouterService } from '@services/router/router.service';

@Injectable({
  providedIn: 'root'
})
export class Router404Guard implements CanActivate {

  constructor(
    private routerService: RouterService
  ) {
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {

    const routeParams = await this.routerService.getRouteParam();
    // console['logger'].log('router-404.guard>canActivate(): routeParams', routeParams);

    return true;

  }
}

<div class="__content">

  <button
    class="__btn --icon-aside --profile"
    mat-stroked-button
    mipBdColor="primary"
    *ngIf="loggedInUser"
    [routerLink]="['/@' + loggedInUser.username]"
  >
    <mip-avatar
      [userModel]="loggedInUser"
      [size]="35"
    ></mip-avatar>
    <span class="__action-content">
        <mip-profile-slug-name mipTextColor="primary" [user]="loggedInUser"></mip-profile-slug-name>
        <small mipTranslate="error.actions.go-profile">
          Go to my profile
        </small>
      </span>
  </button>

  <button
    class="__btn --icon-aside"
    mat-stroked-button
    [routerLink]="['/trending']"
  >
    <mip-icon icon="home" [solid]="true"></mip-icon>
    <span mipTranslate="error.actions.go-trending">
      Go to Trending
    </span>
  </button>

  <button
    class="__btn --icon-aside"
    mat-stroked-button
    [routerLink]="['/trending']"
  >
    <span mipTranslate="error.actions.logout">
      Logout
    </span>
    <mip-icon matIcon="logout"></mip-icon>
  </button>

</div>

import {
  BaseModelFactory,
  BaseModelFactoryInterface
} from '@api/models/';
import {
  ContestWinnerInterface,
  ContestWinnerModel
} from './';

export class ContestWinnerFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = ContestWinnerModel;

  constructor() {
    super();
    // console['logger'].log(ContestWinnerModel);
  }

  override build(data: ContestWinnerInterface): ContestWinnerModel {
    return super.build(data);
  }

  override buildCollection(data: Array<ContestWinnerInterface>): Array<ContestWinnerModel> {
    return super.buildCollection(data);
  }

}

// export const contestFactory = new ContestFactory();

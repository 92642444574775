import { BaseModel } from '@api/models';
import {
  DashboardCampaignInterface,
  getClassificationLabel,
  getClassificationSettings
} from './';

export class DashboardCampaignModel extends BaseModel implements DashboardCampaignInterface {

  end = null;
  id = null;
  name = null;
  region = null;
  start = null;
  status = null;
  type = null;

  createdAt = null;
  updatedAt = null;

  extra = {
    minInstagramFollowers: 0,
    validations: [],
    classifications: [],
    dateLimitToApproval: '',
    dateLimitToSubmit: '',
    limit: 0
  };

  stats = {
    details: {
      classification: {},
      total: 0,
      status: {
        approved: 0,
        auto_rejected: 0,
        pending: 0,
        rejected: 0,
        disqualified: 0
      },
      gender: {
        man: 0,
        woman: 0,
        other: 0
      },
      genderPercentage: {
        man: '0%',
        woman: '0%',
        other: '0%'
      }
    },
    creators: {
      total: 0,
      moj: 0,
      myjosh: 0,
      tiki: 0
    },
    followers: {
      instagram: 0
    }
  };

  classificationsDetails = [];

  hasSocialStats = false; // local
  hasAdditionalSocialMedia = false; // local
  fullName = null; // local
  fullNameHtml = null; // local
  totalQuotasAvailable = 0; // local
  totalQuotasFilled = 0; // local

  constructor(model?: DashboardCampaignInterface) {
    super(model);
    this.fill(model);
  }

  override beforeFill(data: DashboardCampaignInterface) {

    try {
      data.type = data.type || 'ambassador';
      // console['logger'].log('dashboard-campaign.model->beforeFill(): data', data);

      this.modifyExtraClassifications(data);

      if (data.extra) {

        this.totalQuotasAvailable = 0;
        this.classificationsDetails = [];

        if (data.extra.classifications && Array.isArray(data.extra.classifications)) {
          for (const classification of data.extra.classifications) {
            console['logger'].log('dashboard-campaign.model->beforeFill(): classification', classification);

            this.totalQuotasAvailable += classification.limit;
          }
        }

        console['logger'].log('dashboard-campaign.model->beforeFill(): this.totalQuotasAvailable', this.totalQuotasAvailable);

      }

      if (data.stats && data.stats.details) {

        if (data.stats.details.status) {
          this.totalQuotasFilled = data.stats.details.status.approved;
          // console['logger'].log('dashboard-campaign.model->beforeFill(): this.totalQuotasFilled', this.totalQuotasFilled);
        }

        if (data.stats.details.gender) {
          // console['logger'].log('dashboard-campaign.model->beforeFill(): data.stats.details.gender', data.stats.details.gender);

          data.stats.details.genderPercentage = this.stats.details.genderPercentage;
          // console['logger'].log('dashboard-campaign.model->beforeFill(): data.stats.details.genderPercentage', data.stats.details.genderPercentage);

          for (const i in data.stats.details.gender) {
            // console['logger'].log('dashboard-campaign.model->beforeFill(): data.stats.details.gender[i]', i, data.stats.details.gender[i]);
            if (data.stats.details.gender[i]) {
              data.stats.details.genderPercentage[i] = `${((data.stats.details.gender[i] * 100) / data.stats.creators.total).toFixed(2)}%`;
              // console['logger'].log('dashboard-campaign.model->beforeFill(): data.stats.details.genderPercentage[i]', i, data.stats.details.genderPercentage[i]);
            }
            else {
              data.stats.details.genderPercentage[i] = '0%';
            }
          }

          // console['logger'].log('dashboard-campaign.model->beforeFill(): data.stats.details.genderPercentage', data.stats.details.genderPercentage);

        }
      }

    }
    catch (e) {
      console.error('dashboard-campaign.model->beforeFill(): ERROR', e);
    }

  }

  override afterFill(model: DashboardCampaignInterface) {
    try {
      console['logger'].log('dashboard-campaign.model->afterFill(): model ', model);

      this.fullName = `${this.name}${this.type ? ' (' + this.type + ')' : ''}`;
      this.fullNameHtml = `${this.name}${this.type ? ' (<strong>' + this.type + '</strong>)' : ''}`;

      // console['logger'].log('dashboard-campaign.model->afterFill(): model.stats.creators', model.stats.creators);

      if (model.stats) {
        this.hasSocialStats = ('moj' in model.stats.creators) ||
          ('myjosh' in model.stats.creators) ||
          ('tiki' in model.stats.creators);
      }
    }
    catch (e) {
      console.error('dashboard-campaign.model->afterFill(): ERROR', e);
    }
  }

  modifyExtraClassifications(data) {

    try {

      // console['logger'].log('dashboard-campaign.model->modifyExtraClassifications(): extra', type, extra);

      if (data.extra) {

        const {type, stats} = data;

        const campaignSettings = getClassificationSettings(type);
        console['logger'].log('dashboard-campaign.model->modifyExtraClassifications(): campaignSettings', type, campaignSettings);

        data.extra = {...campaignSettings, ...data.extra};
        const extra = data.extra;
        console['logger'].log('dashboard-campaign.model->modifyExtraClassifications(): extra', type, extra);

        if (extra.classifications) {

          let minInstagramFollowers = campaignSettings.minInstagramFollowers;

          const classifications = [];

          if (Array.isArray(extra.classifications)) {
            for (const i in extra.classifications) {
              const classification = extra.classifications[i];

              this.hasAdditionalSocialMedia = !!classification.minSocialFollowers;

              if (classification.minInstagramFollowers < minInstagramFollowers) {
                minInstagramFollowers = classification.minInstagramFollowers;
              }

              if (classification.limit > 0 && classification.active) {
                classification.label = getClassificationLabel(classification, extra.classifications[parseInt(i) + 1]);
                classifications.push(classification);
              }
            }

            campaignSettings.minInstagramFollowers = minInstagramFollowers;
            console['logger'].log('dashboard-campaign.model->modifyExtraClassifications(): campaignSettings.minInstagramFollowers', type, campaignSettings.minInstagramFollowers);

            extra.classifications = classifications;
            console['logger'].log('dashboard-campaign.model->modifyExtraClassifications(): extra.classifications', extra.classifications);

          }

          const {details} = stats;
          // console['logger'].log('dashboard-campaign.model->modifyExtraClassifications(): details', type, details);

          if (details.classification && extra.classifications && Array.isArray(extra.classifications)) {
            // console['logger'].log('dashboard-campaign.model->modifyExtraClassifications(): details.classification', type, details.classification);

            for (const classification of extra.classifications) {
              // console['logger'].log('dashboard-campaign.model->modifyExtraClassifications(): classification', type, classification, details.classification[classification.name]);
              classification.value = details.classification[classification.name] || 0;
            }
          }
          else {
            for (const classification of extra.classifications) {
              classification.value = 0;
            }
          }

          // console['logger'].log('dashboard-campaign.model->modifyExtraClassifications(): extra.classifications: AFTER', type, extra.classifications);

        }

        // console['logger'].log('dashboard-campaign.model->modifyExtraClassifications(): extra', type, extra);

      }

    }
    catch (e) {
      console.error('dashboard-campaign.model->modifyExtraClassifications(): ERROR', e);
    }

  }

  isCampaignFull() {

    console['logger'].log('dashboard-campaign.model->isCampaignFull(): this.stats.details.total', this.stats.details.total);
    console['logger'].log('dashboard-campaign.model->isCampaignFull(): this.extra.limit', this.extra.limit);

    console['logger'].log('dashboard-campaign.model->isCampaignFull(): this.totalQuotasFilled', this.totalQuotasFilled);
    console['logger'].log('dashboard-campaign.model->isCampaignFull(): this.totalQuotasAvailable', this.totalQuotasAvailable);

    if (this.extra.limit) {
      return this.stats.details.total >= this.extra.limit;
    }
    else {
      return this.totalQuotasFilled >= this.totalQuotasAvailable;
    }

  }

  getCampaignSettings() {
    return this.extra;
  }

  validateInstagramFollower(level, followers: number) {
    return followers >= level.minInstagramFollowers;
  }

}



import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ObserverHelper } from '@helpers/observer.helper';
import { TranslateService } from '@ngx-translate/core';
import { MipTranslateService } from './mip-translate.service';

@Directive({
  selector: '[mipTranslate]'
})
export class MipTranslateDirective implements OnInit, OnChanges, OnDestroy {

  @Input() mipTranslate: string;
  @Input() translateValues: object = {};

  observerHelper = new ObserverHelper();

  constructor(
    private translate: TranslateService,
    private elRef: ElementRef,
    // private routerService: RouterService,
    private mipTranslateService: MipTranslateService
  ) {
  }

  ngOnInit() {

    this.mipTranslateService.doTranslation(this.mipTranslate, this.translateValues, this.elRef.nativeElement);

    this.observerHelper.addSubscription(
      this.translate.onLangChange.subscribe(() => {
        this.mipTranslateService.doTranslation(this.mipTranslate, this.translateValues, this.elRef.nativeElement);
      })
    );

  }

  ngOnChanges() {
    this.mipTranslateService.doTranslation(this.mipTranslate, this.translateValues, this.elRef.nativeElement);
  }

  ngOnDestroy() {
    this.observerHelper.unsubscribeAll();
  }

  /*
   linkify(nativeElement, translate) {
   // console['logger'].log('mip-translate.directive->linkify(): nativeElement', nativeElement, translate);

   nativeElement.innerHTML = translate;

   const routerLinks = nativeElement.querySelectorAll('[routerLink]');
   // console['logger'].log('mip-translate.directive->linkify(): routerLinks', routerLinks);

   routerLinks.forEach((elementLink: HTMLAnchorElement) => {
   // console['logger'].log('mip-translate.directive->linkify(): elementLink', elementLink);

   const routerLink = elementLink.getAttribute('routerLink');
   // console['logger'].log('mip-translate.directive->linkify(): routerLink', routerLink);

   elementLink.href = routerLink;

   elementLink.onclick = (e) => {
   e.preventDefault();
   this.routerService.navigateTo(routerLink);
   };
   });

   return translate;
   }

   doTranslation() {
   const nativeElement = this.elRef.nativeElement;

   this.translate.get(this.mipTranslate, this.translateValues).subscribe(translate => {
   // console['logger'].log('mip-translate.directive->doTranslation(): this.mipTranslate', this.mipTranslate, [translate]);
   this.linkify(nativeElement, translate);
   });
   }*/

}

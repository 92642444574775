import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MipAvatarModule } from '@components/mip-avatar/mip-avatar.module';
import { MipIconModule } from '@components/mip-icon/mip-icon.module';
import { MipProfileSlugNameModule } from '@components/mip-profile-slug-name/mip-profile-slug-name.module';
import { ColorModule } from '@directives/color/color.module';
import { MipTranslateModule } from '@directives/translate/mip-translate.module';
import { ErrorActionsModule } from '@modules/error/actions/error-actions.module';

import { NotFoundRoutingModule } from './not-found-routing.module';
import { NotFoundComponent } from './not-found.component';
import { Router404Guard } from './router-404.guard';


@NgModule({
  declarations: [NotFoundComponent],
  imports: [
    CommonModule,
    NotFoundRoutingModule,
    MipIconModule,
    MatButtonModule,
    MipTranslateModule,
    ColorModule,
    MipAvatarModule,
    MipProfileSlugNameModule,
    ErrorActionsModule
  ],
  providers: [
    Router404Guard
  ]
})
export class NotFoundModule {
}

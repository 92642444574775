import { BaseModelFactory, BaseModelFactoryInterface } from '@api/models/';
import { MediaModel } from './';

export class MediaFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = ['id', 'mediaType'];
  override model = MediaModel;

  override build(data: object): MediaModel {
    return super.build(data);
  }

  override buildCollection(data: Array<object>): Array<MediaModel> {
    return super.buildCollection(data);
  }

}

// export const mediaFactory = new MediaFactory();

import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { utilsFactory } from '@factories/utils.factory';

@Directive({
  selector: '[mipTextColor],[mipBgColor],[mipBdColor],[mipHoverColor],[mipBdTopColor],[mipBdRightColor],[mipBdBottomColor],[mipBdLeftColor]'
})
export class ColorDirective implements OnChanges {

  @Input() mipTextColor: string;
  @Input() mipBgColor: string;
  @Input() mipHoverColor: string;

  @Input() mipBdColor: string;
  @Input() mipBdTopColor: string;
  @Input() mipBdRightColor: string;
  @Input() mipBdBottomColor: string;
  @Input() mipBdLeftColor: string;

  lastColor = {
    text: null,
    bg: null,
    bd: null,
    hover: null
  };

  constructor(private elHost: ElementRef) {
    // console['logger'].log('color.directive->constructor(): this.elHost.nativeElement', this.elHost.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges) {

    // console['logger'].log('color.directive->ngOnChanges(): this.elHost.nativeElement', this.elHost.nativeElement);
    // console['logger'].log('color.directive->ngOnChanges(): this.mipBgTopColor', this.mipBgTopColor);

    if ('mipTextColor' in changes) {
      if (this.mipTextColor) {
        this.changeColor('text', this.mipTextColor);
      }
      else {
        this.removeColor('text');
      }
    }

    if ('mipBgColor' in changes) {
      if (this.mipBgColor) {
        this.changeColor('bg', this.mipBgColor);
      }
      else {
        this.removeColor('bg');
      }
    }

    if ('mipBdColor' in changes) {
      if (this.mipBdColor) {
        this.changeColor('bd', this.mipBdColor);
      }
      else {
        this.removeColor('bd');
      }
    }

    if ('mipBdTopColor' in changes) {
      if (this.mipBdTopColor) {
        this.changeColor('bd', this.mipBdTopColor, 'top');
      }
      else {
        this.removeColor('bd', 'top');
      }
    }

    if ('mipBdRightColor' in changes) {
      if (this.mipBdRightColor) {
        this.changeColor('bd', this.mipBdRightColor, 'right');
      }
      else {
        this.removeColor('bd', 'right');
      }
    }

    if ('mipBdBottomColor' in changes) {
      if (this.mipBdBottomColor) {
        this.changeColor('bd', this.mipBdBottomColor, 'bottom');
      }
      else {
        this.removeColor('bd', 'bottom');
      }
    }

    if ('mipBdLeftColor' in changes) {
      if (this.mipBdLeftColor) {
        this.changeColor('bd', this.mipBdLeftColor, 'left');
      }
      else {
        this.removeColor('bd', 'left');
      }
    }

    if ('mipHoverColor' in changes) {
      if (this.mipHoverColor) {
        this.changeColor('hover', this.mipHoverColor);
      }
      else {
        this.removeColor('hover');
      }
    }

  }

  changeColor(type, color, bdDirection?) {

    if (this.lastColor[type] && this.lastColor[type] !== color) {
      // console['logger'].log('color.directive->changeColor(): this.lastColor', `mip-color--${type}${bdDirection ? '-' + bdDirection : ''}-${this.lastColor[type]}`);
      this.removeColor(type, bdDirection);
    }

    // console['logger'].log('color.directive->changeColor(): color', `mip-color--${type}${bdDirection ? '-' + bdDirection : ''}-${color}`);
    utilsFactory.addClass(this.elHost.nativeElement, `mip-color--${type}${bdDirection ? '-' + bdDirection : ''}-${color}`);

    this.lastColor[type] = color;

  }

  removeColor(type, bdDirection?) {
    if (type && this.lastColor[type]) {
      // console['logger'].log('color.directive->removeColor():', `mip-color--${type}${bdDirection ? '-' + bdDirection : ''}-${this.lastColor[type]}`);
      utilsFactory.removeClass(this.elHost.nativeElement, `mip-color--${type}${bdDirection ? '-' + bdDirection : ''}-${this.lastColor[type]}`);
    }
  }

}

import {
  Component,
  Inject,
  OnInit,
  Optional
} from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';

@Component({
  selector: 'mip-general-error',
  templateUrl: './general-error.component.html',
  styleUrls: ['./general-error.component.scss']
})
export class GeneralErrorComponent implements OnInit {

  constructor(
    @Optional() @Inject(RESPONSE) private response: any
  ) {
  }

  ngOnInit(): void {
    if (this.response) {
      this.response.status(500);
    }
  }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MipAvatarModule } from '@components/mip-avatar/mip-avatar.module';
import { MipIconModule } from '@components/mip-icon/mip-icon.module';
import { MipProfileSlugNameModule } from '@components/mip-profile-slug-name/mip-profile-slug-name.module';
import { ColorModule } from '@directives/color/color.module';
import { MipTranslateModule } from '@directives/translate/mip-translate.module';
import { ErrorActionsComponent } from 'src/app/modules/error/actions/error-actions.component';


@NgModule({
  declarations: [
    ErrorActionsComponent
  ],
  imports: [
    CommonModule,
    ColorModule,
    MatButtonModule,
    MipAvatarModule,
    MipIconModule,
    MipProfileSlugNameModule,
    MipTranslateModule,
    RouterLink
  ],
  exports: [
    ErrorActionsComponent
  ]
})
export class ErrorActionsModule {
}

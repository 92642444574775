import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID
} from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';

const MobileDetect = require('mobile-detect');

const {detect} = require('detect-browser');

@Injectable({
  providedIn: 'root'
})
export class MobileDetectService {

  md = null;
  userAgent = null;
  isBrowser = true;
  browserDetect = null;

  simulateIphone = false;

  constructor(
    @Optional() @Inject(REQUEST) private request: any,
    @Inject(PLATFORM_ID) private platform: any
  ) {

    // console['logger'].log('mobile-detect.service->constructor(): MobileDetect', MobileDetect);

    if (this.request) {
      this.userAgent = this.request.get('User-Agent');
    }
    else {
      this.userAgent = window.navigator.userAgent;
    }

    this.md = new MobileDetect(this.userAgent);

    this.browserDetect = detect(this.userAgent);

    // console['logger'].log('mobile-detect.service->constructor(): this.userAgent', this.userAgent);
    // console['logger'].log('mobile-detect.service->constructor(): mobile', this.md.mobile());
    // console['logger'].log('mobile-detect.service->constructor(): phone', this.md.phone());
    // console['logger'].log('mobile-detect.service->constructor(): tablet', this.md.tablet());
    // console['logger'].log('mobile-detect.service->constructor(): userAgent', this.md.userAgent());
    // console['logger'].log('mobile-detect.service->constructor(): os', this.md.os());
    // console['logger'].log('mobile-detect.service->constructor(): iPhone', this.md.is('iPhone'));
    // console['logger'].log('mobile-detect.service->constructor(): bot', this.md.is('bot'));
    // console['logger'].log('mobile-detect.service->constructor(): Webkit', this.md.version('Webkit'));
    // console['logger'].log('mobile-detect.service->constructor(): Build', this.md.versionStr('Build'));
    // console['logger'].log('mobile-detect.service->constructor(): this.isValidBrowser()', this.isValidBrowser());
    // console['logger'].log('mobile-detect.service->constructor(): this.browserDetect.name', this.browserDetect ? this.browserDetect.name : null);
    // console['logger'].log('mobile-detect.service->constructor(): this.browserDetect.version', this.browserDetect ? this.browserDetect.version : null);
    // console['logger'].log('mobile-detect.service->constructor(): this.browserDetect.os', this.browserDetect ? this.browserDetect.os : null);
    // console['logger'].log('mobile-detect.service->constructor(): --------------------------------------------');

    // console['logger'].log('mobile-detect.service->constructor() mobileDetect', this.md);

  }

  isIphone(): boolean {

    if (this.simulateIphone) {
      return true;
    }

    return this.md.is('iPhone');
  }

  isAndroid(): boolean {
    const os = this.md.os();
    return !!(os && os.indexOf('Android') > -1);
  }

  isSafari(): boolean {

    if (this.simulateIphone) {
      return true;
    }

    // console['logger'].log('mobile-detect.service->isSafari() this.userAgent', this.userAgent);
    if (this.userAgent.toLowerCase().indexOf('safari') !== -1) {
      return this.userAgent.toLowerCase().indexOf('chrome') <= -1;
    }
    else {
      return false;
    }
  }

  isMobile(): boolean {

    if (this.simulateIphone) {
      return true;
    }

    return !!this.md.mobile();
  }

  isPhone(): boolean {

    if (this.simulateIphone) {
      return true;
    }

    return this.md.phone();
  }

  isTablet(): boolean {
    return this.md.tablet();
  }

  isValidBrowser() {

    if (this.simulateIphone) {
      return true;
    }

    // console['logger'].log('mobile-detect.service->isValidBrowser(): this.md.version(Webkit)', this.md.version('Webkit'));
    return !!this.browserDetect;
  }

  isBrowserName(browserName: string) {

    if (this.browserDetect) {
      return this.browserDetect.name === browserName;
    }

    return false;

  }

  getBrowserName() {

    if (this.browserDetect) {
      return this.browserDetect.name;
    }

    return null;

  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MipAvatarModule } from '@components/mip-avatar/mip-avatar.module';
import { MipIconModule } from '@components/mip-icon/mip-icon.module';
import { MipProfileSlugNameModule } from '@components/mip-profile-slug-name/mip-profile-slug-name.module';
import { ColorModule } from '@directives/color/color.module';
import { MipTranslateModule } from '@directives/translate/mip-translate.module';
import { ErrorActionsModule } from '@modules/error/actions/error-actions.module';

import { GeneralErrorRoutingModule } from './general-error-routing.module';
import { GeneralErrorComponent } from './general-error.component';


@NgModule({
  declarations: [GeneralErrorComponent],
  imports: [
    CommonModule,
    GeneralErrorRoutingModule,
    MipIconModule,
    MipTranslateModule,
    MipAvatarModule,
    MipProfileSlugNameModule,
    MatButtonModule,
    ColorModule,
    ErrorActionsModule
  ]
})
export class GeneralErrorModule {
}

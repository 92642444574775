import {
  Component,
  Input
} from '@angular/core';

@Component({
  selector: 'mip-loading',
  templateUrl: './mip-loading.component.html',
  styleUrls: ['./mip-loading.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})

export class MipLoadingComponent {
  @Input() size = '8x';
  @Input() color = 'primary';
}

import {
  Component,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { environment } from '@environments/environment';

export interface MipIconInterface {
  icon?: string;
  svgIcon?: string;
  pngIcon?: string;
  gifIcon?: string;
  lottieIcon?: string;
  lottieAutoplay?: boolean;
  lottieStopFrame?: number;
  lottieColored?: boolean;
  // lottieConfig?: LottieInterface;
  size?: string;
  rotate?: number;
  spin?: boolean;
  colored?: boolean;
  circle?: boolean;
  solid?: boolean;
  bold?: boolean;
  light?: boolean;
  block?: boolean;
  busy?: boolean;
  title?: string;
}

@Component({
  selector: 'mip-icon,[mip-icon]',
  templateUrl: './mip-icon.component.html',
  styleUrls: ['./mip-icon.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MipIconComponent implements OnChanges, OnChanges {

  @Input() mipColor = null;

  // mip-icon
  @Input() icon = '';
  @Input() svgIcon = '';
  @Input() pngIcon = '';
  @Input() gifIcon = '';

  // mat-icon
  @Input() matIcon = '';
  @Input() matIconOutlined = false;
  @Input() matIconWeight = 200;
  @Input() matIconGrade = 100;

  // lottie animated json icon
  @Input() lottieIcon = '';
  @Input() lottieAutoplay = true;
  @Input() lottieStopFrame = 0;
  @Input() lottieColored = false;
  // @Input() lottieConfig: LottieInterface = null;

  @Input() forceSize = false;
  @Input() size: any = '4x';
  @Input() iconSize: any = '4x';
  @Input() rotate = 0;
  @Input() reverseHorizontally = false;
  @Input() reverseVertically = false;
  @Input() spin = false;

  @Input() colored = false;
  @Input() circle = false;
  @Input() solid = false;
  @Input() bold = false;
  @Input() light = false;

  @Input() block = false;
  @Input() busy = false;

  @Input() title = '';

  assetsPath = environment.assetsPath;
  blockSize = '6x';
  cb = Date.now();
  fileName = '';
  matIconStyle = {};

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // console['logger'].log('mip-icon.component>ngOnInit(): changes', changes);

    // console['logger'].log('mip-icon.component>ngOnInit(): this.icon', this.icon);
    // console['logger'].log('mip-icon.component>ngOnInit(): this.svgIcon', this.svgIcon);
    // console['logger'].log('mip-icon.component>ngOnInit(): this.pngIcon', this.pngIcon);
    // console['logger'].log('mip-icon.component>ngOnInit(): this.gifIcon', this.gifIcon);
    // console['logger'].log('mip-icon.component>ngOnInit(): this.lottieIcon', this.lottieIcon, this.lottieColored);
    // console['logger'].log('mip-icon.component>ngOnInit(): ----------------------------');

    if (!this.icon && !this.svgIcon && !this.pngIcon && !this.gifIcon && !this.lottieIcon && !this.matIcon) {
      throw new Error('[icon|svgIcon|pngIcon|gifIcon|matIcon|lottieIcon] must be provided.');
    }

    if (!this.size) {
      this.size = '4x';
    }

    if (this.block) {
      // console['logger'].log('mip-icon.component>ngOnInit(): this.size', this.size);
      // tslint:disable-next-line:radix
      this.blockSize = `${parseFloat(this.size) + 2}x`;
    }

    this.fileName = null;

    if (this.svgIcon) {
      this.fileName = this.svgIcon;
    }
    else if (this.pngIcon) {
      this.fileName = this.pngIcon;
    }
    else if (this.gifIcon) {
      this.fileName = this.gifIcon;
    }

    // console['logger'].log('mip-icon.component>ngOnChanges(): this.fileName', this.fileName);

    if (this.fileName && this.solid) {
      this.fileName += '-solid';
    }

    if (this.fileName && this.bold) {
      this.fileName += '-bold';
    }

    if (this.fileName && this.circle) {
      this.fileName += '-circle';
    }

    if (this.fileName && this.colored) {
      this.fileName += '-colored';
    }

    if (this.fileName) {
      if (this.svgIcon) {
        this.fileName += '.svg';
      }
      else if (this.pngIcon) {
        this.fileName += '.png';
      }
      else if (this.gifIcon) {
        this.fileName += '.gif';
      }
    }

    if (
      changes['matIcon'] ||
      changes['matIconFill'] ||
      changes['matIconWeight'] ||
      changes['matIconGrade']
    ) {
      this.matIconStyle = {
        'font-variation-settings': `"FILL" ${this.matIconOutlined ? 0 : 1}, "wght" ${this.matIconWeight}, "GRAD" ${this.matIconGrade}`
      };
    }

  }

}

import {
  BaseModelFactory,
  BaseModelFactoryInterface
} from '@api/models/';
import {
  HashtagInterface,
  HashtagModel
} from './';

export class HashtagFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = HashtagModel;

  constructor() {
    super();
    // console['logger'].log(HashtagModel, HashtagModel);
  }

  override build(data: HashtagInterface): HashtagModel {
    return super.build(data);
  }

  override buildCollection(data: Array<HashtagInterface>): Array<HashtagModel> {
    return super.buildCollection(data);
  }

}

// export const hashtagFactory = new HashtagFactory();

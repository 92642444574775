import {
  BaseModelFactory,
  BaseModelFactoryInterface
} from '@api/models/';
import {
  ContestInterface,
  ContestModel
} from './';

export class ContestFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = ContestModel;

  constructor() {
    super();
    // console['logger'].log(ContestModel, ContestModel);
  }

  override build(data: ContestInterface): ContestModel {
    return super.build(data);
  }

  override buildCollection(data: Array<ContestInterface>): Array<ContestModel> {
    return super.buildCollection(data);
  }

}

// export const contestFactory = new ContestFactory();

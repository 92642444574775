import { Injectable } from '@angular/core';
import { PostModel } from '@api/models';
import { PaginationInterface } from '@api/services/pagination.interface';
import { UserService } from '@api/services/user.service';
import { ModelFactoryService } from '@services/model-fectory/model-factory.service';
import { ResourceService } from '@services/resource/resource.service';

export interface GetPostsParamsInterface {
  limit?: number; // default 10
  page?: number; // just for trending and search api calls
  hashtag?: string;
  lastId?: string | number;
  hashtagId?: string | number;
  contestId?: string | number;
  orderByCreatedAt?: string;
  onlyVideos?: boolean;
  fromCache?: boolean;
  category?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  static instance: PostsService;

  constructor(
    private resourceService: ResourceService,
    private modelFactory: ModelFactoryService,
    private userService: UserService
  ) {
    PostsService.instance = this;
  }

  /**
   * Method to get the Trending posts
   */
  async getTrendingPosts(params?: GetPostsParamsInterface): Promise<{ pagination: PaginationInterface, list: Array<PostModel> }> {
    try {

      console['logger'].log('posts.service->getTrendingPosts(): params', params);

      params = params || {};

      params.limit = params.limit || 10;
      const category = params.category; //  === 'following' ? 'following' : 'trending';

      const finalList = [];

      const response = await this.resourceService.get('api', {
        resource: 'posts',
        path: `/post/${category}`, // trending-web
        params
      });
      console['logger'].log('posts.service->getTrendingPosts(): response', response);

      for (const post of response.items) {
        if (post.score !== 0) {
          finalList.push(post);
        }
      }

      const lastId = finalList[finalList.length - 1];
      console['logger'].log('posts.service->getTrendingPosts(): lastId', lastId.id);

      const _pagination = {
        totalItems: response.totalCount,
        page: response.currentPageNumber,
        nextPage: typeof response.currentPageNumber === 'number' ? response.currentPageNumber + 1 : null,
        limit: response.numItemsPerPage,
        lastId: response.lastId || lastId.id,
        hasMoreItems: response.hasMoreItems
      };

      return {
        pagination: _pagination,
        list: this.modelFactory.postFactory.buildCollection(finalList)
      };

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to get the hashtag posts
   */
  async getHashtagPosts(params?: GetPostsParamsInterface): Promise<{ pagination: PaginationInterface, list: Array<PostModel> }> {
    try {

      params = params || {};
      params.limit = params.limit || 10;

      // console['logger'].log('posts.service->getHashtagPosts(): params', params);

      const response = await this.resourceService.get('api', {
        resource: 'posts',
        path: `/hashtags/page/${params.hashtagId}/posts`,
        params,
        fromCache: params.fromCache
      });

      // console['logger'].log('posts.service->getHashtagPosts(): response', response);

      const postList = [];

      for (const post of response.items) {
        if (post.score > 0) {
          postList.push(post);
        }
      }

      return {
        pagination: response._pagination,
        list: this.modelFactory.postFactory.buildCollection(postList)
      };

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to get the hashtag posts
   */
  async getContestPosts(params?: GetPostsParamsInterface): Promise<{ pagination: PaginationInterface, list: Array<PostModel> }> {
    try {

      params = params || {};
      params.limit = params.limit || 10;

      // console['logger'].log('posts.service->getContestPosts(): params', params);

      const response = await this.resourceService.get('api', {
        resource: 'posts',
        path: `/contest-view/posts/${params.contestId}`,
        params
      });

      // console['logger'].log('posts.service->getContestPosts(): response', response);

      const postList = [];

      for (const post of response.items) {
        if (post.score > 0) {
          postList.push(post);
        }
      }

      return {
        pagination: response._pagination,
        list: this.modelFactory.postFactory.buildCollection(postList)
      };

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to get the hashtag top10 posts
   */
  async getHashtagTop10Posts(hashtag): Promise<Array<PostModel>> {
    try {

      // console['logger'].log('posts.service->getHashtagTop10Posts()');

      const response = await this.resourceService.get('api', {
        resource: 'posts',
        path: `/hashtags/${hashtag}/top10`
      });

      // console['logger'].log('posts.service->getHashtagTop10Posts(): response', Array.isArray(response), response);

      return this.modelFactory.postFactory.buildCollection(response);

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to get a post
   */
  async getPost(postId: string, fromSsr = true): Promise<PostModel> {

    try {

      // console['logger'].log('posts.service->getPost(): postId', postId);

      /*if (fromSsr) {
       try {

       const postFromSrr = this.getSsrPost(postId);
       // console['logger'].log('posts.service->getPost(): postFromSrr', postFromSrr);

       if (postFromSrr) {
       // console['logger'].log('posts.service->getPost(): RESPONDING FROM SSR', postId);
       return this.modelFactory.postFactory.build(postFromSrr);
       }

       }
       catch (e) {
       // console['logger'].log('posts.service->getPost(): NO DATA FROM SSR', postId);
       }
       }*/

      const response = await this.resourceService.get('api', {
        resource: 'posts',
        path: `/post/${postId}`
      });

      if (response.score === 0) {
        throw new Error('Not found');
      }

      // console['logger'].log('posts.service->getPost(): response', response);

      return this.modelFactory.postFactory.build(response);

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to get the posts from SSR
   */
  getSsrPost(postId): PostModel {
    try {

      const response = this.resourceService.getResourceFromSsrData('api', {
        resource: 'posts',
        path: `/post/${postId}`
      });

      return this.modelFactory.postFactory.build(response);
    }
    catch (e) {
      throw e;
    }
  }

}

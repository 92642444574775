import {
  BaseModel,
  ContestInterface,
  HashtagModel,
  PostModel
} from '@api/models/';
import { ModelFactoryService } from '@services/model-fectory/model-factory.service';

import moment from 'moment';

export class ContestModel extends BaseModel implements ContestInterface {

  status = null; // api
  id = null; // api
  startDate = null; // api
  endDate = null; // api
  active = null; // api
  views = null; // api
  description = null; // api
  minutesToFinish = null; // api
  createdAt = null; // api
  totalPosts = null; // api
  updatedAt = null; // api

  hashtag: HashtagModel = null; // api
  posts = []; // api
  winners = null; // api

  constructor(model?: ContestInterface) {
    super(model);
    this.fill(model);
  }

  override afterFill(model) {
    console['logger'].log('contest.model->afterFill(): model', model);

    if (model.hashtag) {
      console['logger'].log('contest.model->afterFill(): model.hashtag', model.hashtag);

      this.hashtag = ModelFactoryService.instance.hashtagFactory.build(model.hashtag);
      this.hashtag.contest = this;
    }

    if (model.posts && model.posts.length) {
      // console['logger'].log('contest.model->afterFill(): model.posts', model.posts);

      this.posts = [];
      const posts = ModelFactoryService.instance.postFactory.buildCollection(model.posts);
      this.addPosts(posts);

    }

    if (model.winners) {
      this.winners = {
        winner: ModelFactoryService.instance.contestWinnerFactory.build(model.winners.winner),
        second: ModelFactoryService.instance.contestWinnerFactory.build(model.winners.second),
        joker: ModelFactoryService.instance.contestWinnerFactory.build(model.winners.joker)
      };
    }
  }

  hasEnded() {
    return moment.utc().isAfter(moment(this.endDate));
  }

  isActive() {
    return this.status === 'in progress' && !this.hasEnded();
  }

  addPost(post: PostModel) {
    const isOnTheList = !!this.posts.filter(item => item === post).length;

    if (!isOnTheList) {
      this.posts.push(post);
    }
  }

  addPosts(posts: Array<PostModel>) {
    for (const post of posts) {
      this.addPost(post);
    }
  }

}

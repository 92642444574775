import { BaseModel } from '@api/models/';
import { DashboardUserInterface } from './';

export class DashboardUserModel extends BaseModel implements DashboardUserInterface {

  createdAt = null;
  email = null;
  id = null;
  influencerUserId = null;
  name = null;
  roles = null;
  token = null;
  updatedAt = null;

  constructor(model?: DashboardUserInterface) {
    super(model);
    this.fill(model);
  }

}

import { Injectable } from '@angular/core';
import { UserService } from '@api/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { RouterService } from '@services/router/router.service';

@Injectable({
  providedIn: 'root'
})
export class MipTranslateService {

  constructor(
    private translate: TranslateService,
    private routerService: RouterService,
    private userService: UserService
  ) {
  }

  linkify(nativeElement, translate) {
    // console['logger'].log('mip-translate.directive->linkify(): nativeElement', nativeElement, translate);

    nativeElement.innerHTML = translate;

    const routerLinks = nativeElement.querySelectorAll('[routerLink]');
    // console['logger'].log('mip-translate.directive->linkify(): routerLinks', routerLinks);

    routerLinks.forEach((elementLink: HTMLAnchorElement) => {
      // console['logger'].log('mip-translate.directive->linkify(): elementLink', elementLink);

      let routerLink = elementLink.getAttribute('routerLink');
      // console['logger'].log('mip-translate.directive->linkify(): routerLink', routerLink);

      if (routerLink.indexOf('/') !== 0) {
        routerLink = `${this.routerService.getCurrentPathName()}/${routerLink}`;
      }

      elementLink.href = routerLink;
      // console['logger'].log('mip-translate.directive->linkify(): elementLink.href', elementLink.href);

      elementLink.onclick = (e) => {
        e.preventDefault();
        // console['logger'].log('mip-translate.directive->onclick(): routerLink', routerLink);
        this.routerService.navigateTo(routerLink);
      };

    });

    return translate;
  }

  doTranslation(mipTranslate, translateValues, nativeElement) {

    const subscription = this.translate.get(mipTranslate, translateValues).subscribe(translate => {
      // console['logger'].log('mip-translate.directive->doTranslation(): this.mipTranslate', this.mipTranslate, [translate]);
      this.linkify(nativeElement, translate);
    });

    if (this.userService.isAuthenticated()) {
      subscription.unsubscribe();
    }

    // console['logger'].log('mip-translate.directive->doTranslation(): subscription', subscription);

  }
}

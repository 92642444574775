<div class="__error-content">

  <div class="__error-header">
    <h1>404</h1>
    <mip-icon
      class="__badge-icon"
      icon="crying-face"
      size="12x"
    ></mip-icon>
  </div>

  <p
    class="__content mip-color--text-white --text-align--center"
    mipTranslate="error.not-found.message"
  >
    The page you are trying to access does not exist.
  </p>
</div>

<div class="__actions-content">
  <mip-error-actions></mip-error-actions>
</div>

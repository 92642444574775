<ng-content></ng-content>

<i
  *ngIf="block"
  class="__icon --block mip-icon-block-bold"
  [class]="['gc-icon--size-' + blockSize]"
  [class.--force]="forceSize"
  [class.--reverse-horizontally]="reverseHorizontally"
  [class.--reverse-vertically]="reverseVertically"
  [style.transform]="'rotate(' + rotate + 'deg)'"
  [class.--spin]="busy || spin"
  [title]="title"
  [mipTextColor]="mipColor"
></i>

<i
  *ngIf="icon"
  class="__icon"
  [class]="[
    'mip-icon-' + (busy ? 'refresh' : icon) + (circle ? '-circle' : '') + (solid ? '-solid' : '') + (bold ? '-bold' : '') + (light ? '-light' : ''),
    'gc-icon--size-' + size
  ]"
  [class.--force]="forceSize"
  [class.--reverse-horizontally]="reverseHorizontally"
  [class.--reverse-vertically]="reverseVertically"
  [style.transform]="'rotate(' + rotate + 'deg)'"
  [class.--spin]="busy || spin"
  [title]="title"
  [mipTextColor]="mipColor"
></i>

<img
  *ngIf="fileName"
  class="__icon"
  [class]="['gc-icon--size-' + size]"
  [class.--force]="forceSize"
  [class.--reverse-horizontally]="reverseHorizontally"
  [class.--reverse-vertically]="reverseVertically"
  [style.transform]="'rotate(' + rotate + 'deg)'"
  [class.--spin]="busy || spin"
  src="{{assetsPath}}/images/icons/{{fileName}}"
  [title]="title"
  [mipTextColor]="mipColor"
>

<mat-icon
  *ngIf="matIcon"
  class="__icon"
  [class.material-symbols-outlined]="matIconOutlined"
  [class]="['gc-icon--size-' + size]"
  [class.--force]="forceSize"
  [class.--reverse-horizontally]="reverseHorizontally"
  [class.--reverse-vertically]="reverseVertically"
  [class.--spin]="busy || spin"
  [style.transform]="'rotate(' + rotate + 'deg)'"
  [style]="matIconStyle"
  [title]="title"
  [mipTextColor]="mipColor"
>
  {{matIcon}}
</mat-icon>

<mip-lottie
  *ngIf="lottieIcon"
  class="__icon"
  [class]="['gc-icon--size-' + size]"
  [class.--force]="forceSize"
  [class.--reverse-horizontally]="reverseHorizontally"
  [class.--reverse-vertically]="reverseVertically"
  [style.transform]="'rotate(' + rotate + 'deg)'"
  [title]="title"
  [lottieIcon]="lottieIcon"
  [autoplay]="lottieAutoplay"
  [stopFrame]="lottieStopFrame"
  [colored]="lottieColored"
></mip-lottie>

<ng-content select="[after]"></ng-content>
<ng-content select="[floating]"></ng-content>

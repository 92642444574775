import { Injectable } from '@angular/core';
import {
  AudioFactory,
  AvatarFactory,
  BaseModelFactory,
  CollabAmbassadorFactory,
  CollabBrandFactory,
  CollabCampaignFactory,
  CommentFactory,
  ContestFactory,
  ContestWinnerFactory,
  DashboardAgencyFactory,
  DashboardAmbassadorFactory,
  DashboardCampaignFactory,
  DashboardUserFactory,
  HashtagFactory,
  MediaFactory,
  PostFactory,
  TopTenRankingFactory,
  UserDeviceUUIDFactory,
  UserFactory,
  UserSettingsFactory
} from '@api/models';

@Injectable({
  providedIn: 'root'
})
export class ModelFactoryService {

  static instance: ModelFactoryService;

  avatarFactory: BaseModelFactory = new AvatarFactory();
  commentFactory: BaseModelFactory = new CommentFactory();
  contestFactory: BaseModelFactory = new ContestFactory();
  contestWinnerFactory: BaseModelFactory = new ContestWinnerFactory();
  hashtagFactory: BaseModelFactory = new HashtagFactory();
  mediaFactory: BaseModelFactory = new MediaFactory();
  postFactory: BaseModelFactory = new PostFactory();
  topTenRankingFactory: BaseModelFactory = new TopTenRankingFactory();
  userFactory: BaseModelFactory = new UserFactory();
  userSettingsFactory: BaseModelFactory = new UserSettingsFactory();
  audioFactory: BaseModelFactory = new AudioFactory();
  userDeviceUUIDFactory: BaseModelFactory = new UserDeviceUUIDFactory();

  // DASHBOARD FACTORIES
  dashboardUserFactory: BaseModelFactory = new DashboardUserFactory();
  dashboardCampaignFactory: BaseModelFactory = new DashboardCampaignFactory();
  dashboardAgencyFactory: BaseModelFactory = new DashboardAgencyFactory();
  dashboardAmbassadorFactory: BaseModelFactory = new DashboardAmbassadorFactory();

  // COLLAB FACTORIES
  collabBrandFactory: BaseModelFactory = new CollabBrandFactory();
  collabCampaignFactory: BaseModelFactory = new CollabCampaignFactory();
  collabAmbassadorFactory: BaseModelFactory = new CollabAmbassadorFactory();

  constructor() {
    ModelFactoryService.instance = this;
    // console['logger'].log('model-factory.service->constructor(): this', this);
  }

}

import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class ObserverHelper {

  private _storage: Subscription[] = [];

  addSubscription(observable: Subscription | Subscription[]): void {
    if (Array.isArray(observable)) {
      for (const obs of observable) {
        this._storage.push(obs);
      }
    }
    else {
      this._storage.push(observable);
    }
  }

  unsubscribeAll(): void {
    for (const observable of this._storage) {
      observable.unsubscribe();
    }
    this._storage = [];
  }

}

import {
  ContestModel,
  UserModel
} from '@api/models/';
import { ModelFactoryService } from '@services/model-fectory/model-factory.service';
import { CollabBrandInterface } from './';

export class CollabBrandModel extends UserModel implements CollabBrandInterface {

  contests: ContestModel[] = null;

  constructor(model?: CollabBrandInterface) {
    // console['logger'].log('collab-brand.model->constructor(): model', model);
    super(model);
    this.fill(model);
  }

  override afterFill(model: CollabBrandInterface) {
    console['logger'].log('collab-brand.model->afterFill(): model', model);

    if (model.contests && model.contests.length > 0) {
      model.contests = ModelFactoryService.instance.contestFactory.buildCollection(model.contests);
    }

    super.afterFill(model);

    console['logger'].log('collab-brand.model->afterFill(): this', this);

  }

}

import { BaseModelFactory, BaseModelFactoryInterface } from '@api/models/';
import { AudioModel } from './';

export class AudioFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override model = AudioModel;

  constructor() {
    super();
  }

  override build(data: object): AudioModel {
    return super.build(data);
  }

  override buildCollection(data: Array<object>): Array<AudioModel> {
    return super.buildCollection(data);
  }

}

// export const mediaFactory = new MediaFactory();

import { BaseModel } from '@api/models/';
import { DashboardAgencyInterface } from './';

export class DashboardAgencyModel extends BaseModel implements DashboardAgencyInterface {

  id = null;
  email = null;
  username = null;
  name = null;

  constructor(model?: DashboardAgencyInterface) {
    super(model);
    this.fill(model);
  }

}

<div class="downtime">

  <!-- IMAGE -->
  <div class="downtime__image">
    <img [src]="assetsPath + '/images/downtime-image.png'">
  </div>

  <!-- DESCRIPTION -->
  <div class="downtime__description">

    <!-- TITLE -->
    <div class="downtime__title" mipTranslate="error.downtime.back-soon">
      We`ll be back soon!
    </div>

    <p mipTranslate="error.downtime.p1">GotChosen is under maintenance.</p>
    <p mipTranslate="error.downtime.p2">Try again in 30 minutes.</p>
    <p mipTranslate="error.downtime.p3">Thanks for your patience.</p>

  </div>

</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MipTranslateComponent } from './mip-translate.component';
import { MipTranslateDirective } from './mip-translate.directive';


@NgModule({
  declarations: [MipTranslateDirective, MipTranslateComponent],
  imports: [
    CommonModule,
    // SafeHtmlModule,
    RouterModule
  ],
  exports: [MipTranslateDirective, MipTranslateComponent]
})
export class MipTranslateModule {
}

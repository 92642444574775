import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
import { environment } from '@environments/environment';
import { utilsFactory } from '@factories/utils.factory';

export interface LottieInterface {
  autoplay?: boolean;
  stopFrame?: number;
}

@Component({
  selector: 'mip-lottie',
  templateUrl: './mip-lottie.component.html',
  styleUrls: ['./mip-lottie.component.scss']
})
export class MipLottieComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('lottieContainer') lottieContainer: ElementRef;
  @ViewChild('lottieColoredContainer') lottieColoredContainer: ElementRef;

  @Input() lottieIcon = '';
  @Input() autoplay = false;
  @Input() stopFrame = 0;
  @Input() colored = false;
  // @Input() lottieConfig: LottieInterface = null;

  assetsPath = environment.assetsPath;

  lottieContainerEl = null;
  lottieColoredContainerEl = null;

  lottieApi = null;
  lottieColoredApi = null;

  /*
   lottieContainerEl = null;
   lottieApi = null;
   wasColored = false;
   */

  constructor() {
    // console['logger'].log('mip-lottie.component->constructor()');
  }

  ngOnInit() {
    if (!this.lottieIcon) {
      throw new Error('[lottieIcon] must be provided.');
    }
  }

  async ngAfterViewInit() {

    if (utilsFactory.isBrowser && this.lottieIcon) {

      this.lottieContainerEl = this.lottieContainer.nativeElement;
      // console['logger'].log('mip-lottie.component->loadIcon(): this.lottieContainerEl', this.lottieContainerEl);

      this.lottieColoredContainerEl = this.lottieColoredContainer.nativeElement;
      // console['logger'].log('mip-lottie.component->loadIcon(): this.lottieColoredContainerEl', this.lottieColoredContainerEl);

      if (this.lottieIcon) {

        const iconPath = `${this.assetsPath}/images/icons/lottie/${this.lottieIcon}.json`;
        // console['logger'].log('mip-lottie.component->loadIcon(): iconPath', iconPath);

        this.lottieApi = window['bodymovin'].loadAnimation({
          container: this.lottieContainerEl,
          path: iconPath,
          renderer: 'svg',
          autoplay: this.autoplay
        });
        // console['logger'].log('mip-lottie.component->loadIcon(): this.lottieApi', this.lottieApi);

        this.lottieApi.setCurrentRawFrameValue(this.stopFrame || 0);

        if (this.colored) {

          const iconColoredPath = `${this.assetsPath}/images/icons/lottie/${this.lottieIcon}-colored.json`;
          // console['logger'].log('mip-lottie.component->loadIcon(): iconColoredPath', iconColoredPath);

          this.lottieColoredApi = window['bodymovin'].loadAnimation({
            container: this.lottieColoredContainerEl,
            path: iconColoredPath,
            renderer: 'svg',
            autoplay: this.autoplay
          });
          // console['logger'].log('mip-lottie.component->loadIcon(): this.lottieColoredApi', this.lottieColoredApi);

          this.lottieColoredApi.setCurrentRawFrameValue(this.stopFrame || 0);

        }

      }

    }
  }

  async ngOnChanges(changes) {

    if (this.lottieContainerEl) {

      // console['logger'].log('mip-lottie.component->ngOnChanges(): this.lottieApi', this.lottieApi);
      // console['logger'].log('mip-lottie.component->ngOnChanges(): this.lottieColoredApi', this.lottieColoredApi);

      if (this.lottieApi) {

        if (this.autoplay) {
          this.lottieApi.play();
        }
        else {
          this.lottieApi.pause();
        }

      }

      if (this.lottieColoredApi) {
        if (this.autoplay) {
          this.lottieColoredApi.play();
        }
        else {
          this.lottieColoredApi.pause();
        }
      }
    }

  }


}

import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import {
  makeStateKey,
  TransferState
} from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { utilsFactory } from '@factories/utils.factory';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateService } from '@ngx-translate/core';
import { MetaTagsService } from '@services/metatags/metatags.service';
import { RouterService } from '@services/router/router.service';
import { WebsocketService } from '@services/websocket/websocket.service';

@Component({
  selector: 'mip-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  assetsPath = environment.assetsPath;
  deployVersion = environment.appVersion;
  // deployDateTime = environment.deployTimestamp;

  isMobile = true;

  isProduction = environment.environmentName === 'production';
  environmentName = environment.environmentName.toUpperCase();

  constructor(
    private routerService: RouterService,
    private translate: TranslateService,
    private metaTagsService: MetaTagsService,
    private transferState: TransferState,
    @Optional() @Inject('USER_IP') userIp: string,
    @Optional() @Inject('USER_COUNTRY') userCountry: string,
    @Inject(DOCUMENT) private document: Document,
    @Optional() @Inject(REQUEST) private request: any,
    private websocket: WebsocketService
  ) {

    // SET USER IP AND COUNTRY
    if (!userIp) {
      const USER_IP = makeStateKey<string>('USER_IP');
      userIp = transferState.get(USER_IP, null);
    }

    if (!userCountry) {
      const USER_COUNTRY = makeStateKey<string>('USER_COUNTRY');
      userCountry = transferState.get(USER_COUNTRY, null);
    }

    console['logger'].log('app.component->constructor(): userIp', userIp);
    console['logger'].log('app.component->constructor(): userCountry', userCountry);

    // SET DEFAULT METATAG DESCRIPTION
    /*this.translate.get('metatag.description').subscribe(async (description) => {
     await this.metaTagsService.setMetaTags({description});
     });*/

    // WATCH FOR ROUTE TO CHANGE
    this.routerService.onRouteChange().subscribe(route => {
      console['logger'].log('app.component->constructor(): gcApp_onRouteChange(): route', route);

      try {
        if (utilsFactory.isBrowser) {
          if ('gcApp_onRouteChange' in window) {
            // @ts-ignore
            window['gcApp_onRouteChange'].postMessage(JSON.stringify(route));
          }
          else {
            throw new Error(`'gcApp_onRouteChange' is not defined`);
          }
        }
      }
      catch (e) {
        // console['logger'].warn('app.component->constructor(): WARNING', e);
      }
    });

    this.routerService.onNavigationEnded().subscribe(event => {
      console['logger'].log('app.component->constructor(): onNavigationEnded(): event', event);
    });

  }

  async ngOnInit() {

    /*const queryParams = await this.routerService.getRouteQueryParam();
     console['logger'].log('app.component->ngOnInit(): queryParams', queryParams);

     // let credentials = null;

     /!*if (queryParams.token) {
     try {
     credentials = JSON.parse(utilsFactory.atob(decodeURIComponent(queryParams.token)));
     }
     catch (e) {
     console.error('app.component->ngOnInit(): ERROR', e);
     }
     }*!/

     if (queryParams.deviceUUID) {
     // this.userService.setDeviceUUID(queryParams.deviceUUID);
     this.resourceService.setDeviceUUID(queryParams.deviceUUID);
     }

     // console['logger'].log('app.component->ngOnInit(): credentials', credentials);
     // await this.userService.init(credentials);*/

    this.websocket.onCommand('under-maintenance').subscribe(response => {
      console['logger'].log('app.component->ngOnInit(): websocket', response);

      const url = this.routerService.getCurrentUrl();
      console['logger'].log('app.component->ngOnInit(): url', url);

      if (response.in_maintenance === true && url.indexOf('/downtime') === -1) {

        this.routerService.registerGoBackReferer({
          path: '/downtime',
          redirectUrl: url
        });

        this.routerService.navigateTo('/downtime');
      }

    });

  }

  ngOnDestroy() {
  }

}

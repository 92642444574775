import {
  Inject,
  Injectable,
  Optional
} from '@angular/core';
import {
  Meta,
  Title
} from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { utilsFactory } from '@factories/utils.factory';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { RouterService } from '@services/router/router.service';

export interface MetaTagsInterface {
  title?: string;
  description?: string;
  image?: string;
  twitterUsername?: string;
  url?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {

  constructor(
    private title: Title,
    private meta: Meta,
    private routerService: RouterService,
    @Optional() @Inject(REQUEST) private request: any
  ) {
  }

  async setMetaTags(data: MetaTagsInterface) {
    try {

      // console['logger'].log('metatags.service->setMetaTags(): data', data);

      const route = await this.routerService.getRouteObject();
      // console['logger'].log('metatags.service->setMetaTags(): route', route);

      const disableSmartBanner = environment.globalQueryParams.isWithinWebView === true || route.data.smartBanner === false || route.queryParams.smartBanner === 'false' || false;
      // console['logger'].log('metatags.service->setMetaTags(): disableSmartBanner', disableSmartBanner);

      // title
      if (data.title) {
        const rgx = new RegExp('\n|<br>|<br\/>', 'g');
        const title = data.title.replace(rgx, ' ').replace(/\s\s/g, ' ').replace(/\s\s/g, ' ');
        // console['logger'].log('metatags.service->setMetaTags(): title', title);

        await this.title.setTitle(title);
        await this.meta.updateTag({property: 'og:title', content: title});
        await this.meta.updateTag({property: 'twitter:title', content: title});
      }

      // description
      if (data.description) {
        const rgx = new RegExp('\n|<br>|<br\/>', 'g');
        const description = data.description.replace(rgx, ' ').replace(/\s\s/g, ' ').replace(/\s\s/g, ' ');
        // console['logger'].log('metatags.service->setMetaTags(): description', description);

        await this.meta.updateTag({name: 'description', content: description});
        await this.meta.updateTag({property: 'og:description', content: description});
        await this.meta.updateTag({property: 'twitter:description', content: description});
      }

      // image
      if (data.image) {
        await this.meta.updateTag({property: 'og:image', content: data.image});
        await this.meta.updateTag({property: 'twitter:image', content: data.image});
      }

      // other
      await this.meta.updateTag({property: 'og:url', content: (data.url || this.getPageUrl())});
      await this.meta.updateTag({property: 'fb:app_id', content: environment.facebookAppId});

      // smart banner (iOS)
      if (disableSmartBanner !== true) {
        await this.meta.updateTag({name: 'apple-itunes-app', content: `app-id=1536151089, app-argument=${data.url || this.getPageUrl()}`});

        if (utilsFactory.isBrowser) {

          /*await this.meta.updateTag({name: 'smartbanner:title', content: 'GotChosen'});
           await this.meta.updateTag({name: 'smartbanner:author', content: 'GotChosen, Inc.'});
           await this.meta.updateTag({name: 'smartbanner:price', content: 'Free'});
           await this.meta.updateTag({name: 'smartbanner:disable-positioning', content: 'true'});
           await this.meta.updateTag({name: 'smartbanner:price-suffix-google', content: ' in Google Play'});
           await this.meta.updateTag({name: 'smartbanner:icon-google', content: 'https://cdn.gotchosen.com/mip/ssr/assets/favicon/android-icon-192x192.png'});
           await this.meta.updateTag({name: 'smartbanner:button', content: 'VIEW'});
           await this.meta.updateTag({name: 'smartbanner:button-url-google', content: 'https://play.google.com/store/apps/details?id=com.gotchosen.influencers'});
           await this.meta.updateTag({name: 'smartbanner:close-label', content: 'Close'});*/

          await utilsFactory.waitToBeTrue('metatags.service', () => window['smartbanner']);
          window['smartbanner'].publish();

        }

      }

      if (data.twitterUsername) {
        await this.meta.updateTag({property: 'twitter:site', content: data.twitterUsername});
      }

      await new Promise(resolve => {
        setTimeout(() => {
          resolve(true);
        }, 100);
      });

    }
    catch (e) {
      throw e;
    }
  }

  getPageUrl() {
    if (this.request) {
      return 'https:' + environment.appBaseHrefUseValue + this.request.originalUrl;
    }
    else {
      return window.location.href;
    }
  }
}

<div
  class="__deploy-version"
  [class.--hide]="isProduction"
  [class.--is-mobile]="isMobile"
>
  [{{environmentName}}] build: {{deployVersion}}
</div>

<div class="loading">
  <mip-loading></mip-loading>
</div>

<div class="content">
  <router-outlet></router-outlet>
</div>

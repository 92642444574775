import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { MipIconModule } from '../mip-icon/mip-icon.module';
import { MipAvatarComponent } from './mip-avatar.component';


@NgModule({
  declarations: [MipAvatarComponent],
  exports: [
    MipAvatarComponent
  ],
  imports: [
    CommonModule,
    MipIconModule,
    RoundProgressModule
  ]
})
export class MipAvatarModule {
}

import Millify from 'millify';

const defaultClassificationSettings = {
  minInstagramFollowers: 10000,
  validations: [
    'hasAdsProgram',
    'hasWalletProgram',
    'hasProfilePicture',
    'hasProfileBio',
    'hasMinPosts'
  ]
};

const DashboardCampaignSettings = {
  tier2: {
    // minInstagramFollowers: 1000,
    validations: [
      'hasWalletProgram',
      'hasProfilePicture',
      'hasProfileBio',
      'hasMinPosts'
    ]
  },
  tier3: {
    // minInstagramFollowers: 10000,
    validations: [
      'hasWalletProgram',
      'hasProfilePicture',
      'hasProfileBio',
      'hasMinPosts'
    ]
  }
};

export const getClassificationSettings = (type) => {

  const campaignSettings = DashboardCampaignSettings[type] || {};
  console['logger'].log('dashboard-campaigns-settings->getClassificationSettings(): campaignSettings', type, campaignSettings);

  return {...defaultClassificationSettings, ...campaignSettings};

};

export const getClassificationLabel = (classification, nextClassification?) => {

  const minFollowers = classification.minSocialFollowers || classification.minInstagramFollowers;

  const minInstagramFollowers = Millify(minFollowers, {precision: 2});
  let label = `LEVEL ${classification.name.toUpperCase()} `;

  if (nextClassification) {
    const nextMinFollowers = nextClassification.minSocialFollowers || nextClassification.minInstagramFollowers;
    const nextMinInstagramFollowers = Millify(nextMinFollowers - 10, {precision: 2});
    label += `(${minInstagramFollowers} - ${nextMinInstagramFollowers} Followers)`;
  }
  else {
    label += `(>= ${minInstagramFollowers} Followers)`;
  }

  console['logger'].log('dashboard-campaigns-settings->getClassificationLabel(): label', label);

  return label;

};
